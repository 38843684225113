import { FC } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import "./our-services.css";
import visualization2 from "./lista_risco.png";
import visualization3 from "./mapa_de_calor.png";
import visualization1 from "./acessos_por_estudantes.png";
import visualization4 from "./pag_inicial.png";
import videoApresentation from "./video-apresentation.mp4";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1
  }
};

const OurServices: FC = () => {
  return (
    <>
      <div id="our-services" className="services_section layout_padding">
        <div className="container">
          <h1 className="services_taital">A solução LANSE</h1>
          <div className="row">
            <div className="col-md-9 col-lg-9">
              <div ><img src={visualization4}/></div>
            </div>
            <div className="col-md-3 col-md-3">
              <div className="box_main">
                <h3 className="technology_text">Predição de Risco Acadêmico</h3>
                <p className="dummy_text">Com o Lanse, sua instituição realiza de forma totalmente automatizada a predição de riscos acadêmicos para auxiliar os gestores a reterem os alunos.</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="box_main">
                <h3 className="technology_text">Visualizações</h3>
                <p className="dummy_text">A solução LANSE disponibiliza na nuvem uma infraestrutura de serviços de predição de risco acadêmico aliada a visualizações sobre as interações dos estudantes.</p>
                <p className="dummy_text">A primeira versão do MVP funciona com dados do ambiente virtual de aprendizagem Moodle e consiste em duas ferramentas que funcionam de maneira conjunta.</p>
                <p className="dummy_text">Essa estratégia e arquitetura teve como motivação principal a de aliviar o trabalho das equipes de TI dos futuros clientes, uma vez que agora, elas serão apenas responsáveis pela instalação de um único plugin (bloco) Moodle e toda as futuras atualizações de funcionalidades dos serviços podem ser realizadas diretamente na nuvem sem a necessidade de envolver os clientes usuários.</p>
              </div>
            </div>
            <div className="col-md-8">
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={true}
                responsive={responsive}
                ssr={false}
                infinite={true}
                autoPlay={false}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={1000}
                containerClass="carousel-container"
                removeArrowOnDeviceType={["tablet", "mobile"]}
                dotListClass="custom-dot-list-style"
                itemClass="image_2"
              >
                <img src={visualization1} alt="example 1" />
                <img src={visualization2} alt="example 2" />
                <img src={visualization3} alt="example 3" />
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurServices;