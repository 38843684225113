import { FC } from "react";
import "./footer.css";

import footerLogoImage from "./footer-logo.png";
import fbImage from "./fb-icon.png"
import twitterImage from "./twitter-icon.png"
import linkedinImage from "./linkedin-icon.png"
import instagramImage from "./instagram-icon.png"

interface IFooterProp {
  email: string;
  telNumber: string;
  telNumber2: string;
  address?: string;
}

const Footer: FC<IFooterProp> = (prop: IFooterProp) => {
  return (
    <>
      <div id="contact" className="footer_section layout_padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-sm-6">
              <div className="footer_logo">
                <img src={footerLogoImage} alt="Footer logo" />
              </div>
              <p className="dolor_text">A tecnologia a favor da redução da reprovação e da evasão escolar.</p>
            </div>
            <div className="col-lg-4 col-sm-6">
              <h4 className="address_text">Contato</h4>
              <p className="dolor_text">{prop.address ? prop.address : ""}</p>
              <p className="dolor_text">{prop.telNumber}</p>
              <p className="dolor_text">{prop.telNumber2}</p>
              <p className="dolor_text">{prop.email}</p>
            </div>
          </div>
          <div className="social_icon">
            <ul>
              <li><a href="#"><img src={fbImage} alt="fb Image" /></a></li>
              <li><a href="#"><img src={twitterImage} alt="twitter Image" /></a></li>
              <li><a href="#"><img src={linkedinImage} alt="linkedin Image" /></a></li>
              <li><a href="#"><img src={instagramImage} alt="instagram Image" /></a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="copyright_section">
        <div className="copyright_text">Copyright 2019 All Right Reserved By <a href="https://html.design">Free html  Templates</a></div>
      </div>
    </>
  );
}

export default Footer;