import { FC } from "react";
import "./header.css";
import logoImage from "./logo.png";

const menu = [
  {
    title: "Sobre nós",
    href: "#aboutus",
    key: "aboutus"
  },
  {
    title: "Nosso produto",
    href: "#our-services",
    key: "our-services"
  },
  {
    title: "Time",
    href: "#our-team",
    key: "our-team"
  },
  {
    title: "Publicações",
    href: "#publications",
    key: "publications"
  },
  {
    title: "Contato",
    href: "#contact",
    key: "contact"
  },
];

const Header: FC = () => {
  return (
    <>
      <div className="header_section">
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <div className="logo">
            <a href="index.html">
              <img src={logoImage} alt="Logo of website"/>
            </a>
          </div>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto">
              {menu.map((item, i) => (
                <li className="nav-item" key={item.key}>
                  <a className="nav-link" href={item.href}>{item.title}</a>
                </li>
              ))}
            </ul>
          </div>
        </nav>
        <div className="banner_main">
          <h1 className="banner_taital">Criatividade e Inovação para a Educação</h1>
          <h1 className="banner_taital_1">LANSE - Predição de Risco Acadêmico</h1>
          <p className="banner_text">A tecnologia a favor da redução da reprovação e da evasão escolar.</p>
          <div className="btn_main">
            <div className="more_bt"><a href="#contact">Contacte-nos</a></div>
          </div>
        </div>
      </div>
      <div className="costume_header">
        <div className="container">
          <div className="menu_main">
            <ul>
              {menu.map((item, i) => (
                <li key={item.key}><a href={item.href}>{item.title}</a></li>
              ))}
            </ul>
          </div>
          <div className="menu_main_1">
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;