import { FC } from "react";
import "./about-us.css"
import aboutUsImage from "./aboutus.svg"

const AboutUs: FC = () => {
  return (
    <div id="aboutus" className="about_section layout_padding">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h1 className="about_taital">Sobre Nós</h1>
            <p className="about_text">Somos parte do Grupo de Pesquisa CrIE, preocupado com a investigação do comportamento humano e desenvolvimento de técnicas, modelos e plataformas educacionais que estimulem o aprendizado significativo com o uso de técnicas de Inteligência Artificial.</p>
            <p className="about_text">O CrIE nasce da parceria de pesquisa entre o Prof. Dr Cristian Cechinel da Universidade Federal de Santa Catarina e o Prof. Dr. Tiago Thompsen Primo da Universidade Federal de Pelotas e hoje conta com pesquisadores de Graduação, Mestrado e Doutorado.
              Além disto, mantém parcerias em P&D&I ativas com a UFPEL, Elimu Social, Indeorum , Udelar (Uruguai) e Universidad de Valparaiso (Chile).</p>
          </div>
          <div className="col-md-6">
            <div>
              <img src={aboutUsImage} alt="About Us image" className="image_1" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;