import { FC } from "react";

import "./our-team.css"

import profile1 from "./profile1.jpg";
import profile2 from "./profile2.jpeg";
import profile3 from "./profile3.jpeg";
import profile4 from "./profile4.jpeg";
import profile5 from "./profile5.jpeg";
import profile6 from "./profile6.jpeg";
import profile8 from "./profile8.jpeg";
import profile9 from "./profile9.jpeg";
import profile10 from "./profile10.jpeg";
import profile11 from "./profile11.jpeg";
import profile12 from "./profile12.jpeg";
import github from "./github.svg";
import linkedin from "./linkedin.svg"
import lattes from "./lattes.svg"

const team = [
  {
    img: profile3,
    name: "Cristian Cechinel",
    function: "Coordenador do projeto",
    resume: "Dr. em Engenharia de Informação e Conhecimento",
    linkedin: "https://www.linkedin.com/in/cristian-cechinel-60480570/",
    lattes: "",
  },
  {
    img: profile6,
    name: "Tiago Primo",
    function: "Assistente de Inovação",
    resume: "Dr. em Computação",
    linkedin: "https://www.linkedin.com/in/tiagoprimo/",
    lattes: "http://lattes.cnpq.br/5641514282351546",
  },
  {
    img: profile5,
    name: "Vinicius Ramos",
    function: "Coordenador da Equipe de Desenvolvimento",
    resume: "Dr. em Computação",
    linkedin: "https://www.linkedin.com/in/vinicius-ramos-0636928/",
    lattes: "http://lattes.cnpq.br/0442142220296336",
  },
  {
    img: profile2,
    name: "Matheus Machado",
    function: "Arquiteto de software",
    resume: "Engenheiro de Computação",
    github: "https://github.com/matheusfrancisco/",
    linkedin: "https://www.linkedin.com/in/matheus-francisco/",
    lattes: "",
  },
  {
    img: profile1,
    name: "Mayara Sousa Stein",
    function: "Desenvolvedora",
    resume: "Bacharel e Mestre em TIC UFSC",
    github: "github.com/MayaraSs",
    linkedin: "https://www.linkedin.com/in/mayara-stein/",
    lattes: "http://lattes.cnpq.br/2928806898651017",
  },
  {
    img: profile8,
    name: "Eduardo de Vila",
    function: "Desenvolvedor",
    resume: "Bacharel em TIC UFSC",
    github: "https://github.com/eduardovila",
    linkedin: "https://www.linkedin.com/in/eduardo-de-vila-103679bb",
    lattes: "http://lattes.cnpq.br/6400965538453194",
  },
  {
    img: profile9,
    name: "Henrique Lemos dos Santos",
    function: "Pesquisador em data science/machine learning",
    resume: "Engenheiro e  Mestre em Computação",
    github: "https://github.com/henriquelds",
    linkedin: "https://www.linkedin.com/in/henrique-lemos-94b53a187/",
    lattes: "http://lattes.cnpq.br/2227937259557858",
  },
  {
    img: profile4,
    name: "Juary Rocha",
    function: "DevOps",
    resume: "Engenheiro de Energias e mestrando em Computação",
    github: "github.com/juaryR",
    linkedin: "https://www.linkedin.com/in/juary-rocha-68027571/",
    lattes: "http://lattes.cnpq.br/6356849291622946",
  },
  {
    img: profile11,
    name: "Valter Alberto Melgarejo Martins",
    function: "Cientista de Dados",
    resume: "Engenheiro de Computação/UFPel",
    github: "github.com/vaaaltin",
    linkedin: "https://www.linkedin.com/in/valter-alberto-melgarejo-martins",
    lattes: "http://lattes.cnpq.br/2723437766037779",
  },
  {
    img: profile12,
    name: "Thomas Michels Rodrigues",
    function: "Desenvolvedor",
    resume: "Bacharel em Ciência da Computação pela FURB",
    github: "https://github.com/thomas-michels",
    linkedin: "https://www.linkedin.com/in/thomas-michels-rodrigues/",
    lattes: "http://lattes.cnpq.br/2842989594432843",
  },
  {
    img: profile10,
    name: "Clayton Reis da Silva",
    function: "Mentor RNP",
    // resume: "",
    // github: "github.com/<>",
    // linkedin: "https://www.linkedin.com/in/<>/",
  }
];

const OurTeam: FC = () => {
  return (
    <>
      <div id="team" className="team_section layout_padding">
        <div className="container">
          <h1 className="team_taital">Nosso Time</h1>
          <div className="team team_section_2 layout_padding" id="our-team">
            {team.map((item, i) => (
              <div key={i}>
                <img src={item.img} alt="profile" />
                <span className="profile-bold">{item.name}</span>
                <span className="profile">{item.function}</span>
                <span className="align resume align">{item.resume} </span>
                <div className="links">
                  <a href={item.github}>
                    <img src={github} alt="github" />
                  </a>
                  <a href={item.linkedin} style={{ margin: "0 15px" }}>
                    <img src={linkedin} alt="linkedin" />
                  </a>
                  <a href={item.lattes}>
                    <img src={lattes} alt="lattes" style={{ width: "38px" }} />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default OurTeam;