import Header from "../shared/header/header";
import Footer from "../shared/footer/footer";
import AboutUs from "../shared/about-us/about-us";
import OurServices from "../shared/our-services/our-services";
import OurTeam from "../shared/our-team/our-team";
import Publications from "../shared/publications/publications";

const App = () => {
  return (
    <>
      <Header></Header>
      <AboutUs></AboutUs>
      <OurServices></OurServices>
      <OurTeam></OurTeam>
      <Publications></Publications>
      <Footer telNumber="+55 (48) 3721-6255" telNumber2="+55 (48) 99805-3412" email="contato@cristiancechinel.pro.br" address="Araranguá, SC"></Footer>
    </>
  );
};

export default App;
