import { FC } from "react";
import "./publications.css"

export interface IPublicationProps {
  title: string;
  locale: string;
  resume: string;
  reference: string;
  link: string;
}

export const Publication: FC<IPublicationProps> = (props: IPublicationProps) => {
  return (
    <div>
      <span className='publi-align-title profile-bold'>
        {props.title}
      </span>
      <span className='publi-align-locale profile'>
        {props.locale}
      </span>
      <span className='publi-align-resume profile'>
        {props.resume}
      </span>
      <span className='publi-align-ref ref'>
        {props.reference}
      </span>
      <button className="internal-button">
        <a target="_blank" href={props.link} style={{ margin: "0 15px", textDecoration: "none" }}>Ver mais</a>
      </button>
    </div>
  );
}